.logo {
    position: fixed;
    top: 3rem;
    left: 3rem;
    z-index: 999;
    max-width: 60px;
}

.navbar {
    z-index: 999;
    .nav-link {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}

@media screen and (min-width: 768px) {
    .navbar {
        width: 100%;
        display: flex;
        height: 93px;
        padding-bottom: 17px;
        align-items: flex-end;
        position: sticky;
        top: 0;
        justify-content: flex-end;
        background-color: rgba(255, 255, 255, 0.95);
    }

}

.dropdown-menu[data-bs-popper] {
    left: auto;
    right: 0;
}
.dropdown-menu {
    font-size: 14px;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: $brand-color-light;
}

.navbar-toggler {
    position: fixed;
    right: 17px;
    top: 25px;
    border: none;
    z-index: 999;
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

@media (max-width: 991px) {
    .navbar-collapse {
        background-color: aliceblue;
        padding: 20px 0 10px;
        position: fixed;
        top: 0;
        width: 100%;
        .dropdown-menu {
            background-color: aliceblue;
            border: none;
            font-size: 13px;
            font-weight: 300;
            color: #222;
            padding: 0;
            margin: 0;
            padding-left: 1rem;
        }
    }
}

.navbar-toggler-icon {
    background: none;
    border-bottom: 3px solid #222;
    padding-top: 17px;
    display: block;
    position: relative;
    &:before, &:after {
        border-top: 3px solid #222;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
    }
    &:before {
        top: 7px;
    }
    &:after {
        bottom: 7px;
    }
}


/*
* This is where all the animation happens
*/
.navbar-toggler-icon,
.navbar-toggler-icon:before,
.navbar-toggler-icon:after {
    transition-property: transform, border-color;
    transition-duration: 0.25s;
    transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
}
   
.navigation-toggle-icon {
    transition-delay: 0.125s;
}
   
.navbar-toggler-icon:before,
.navbar-toggler-icon:after {
    transition-delay: 0;
    transform-origin: center;
}
  
.navbar-toggler:not(.collapsed) {
    .navbar-toggler-icon {
        transition-delay: 0;
        border-color: transparent;
    }
    .navbar-toggler-icon:before {
        transition-delay: 0.125s;
        transform: translateY(3px) rotate(135deg);
    }
         
    .navbar-toggler-icon:after {
        transition-delay: 0.125s;
        transform: translateY(-7px) rotate(-135deg);
    }
}


  
  