.extra-links {
    margin-top: .25rem;
    a {
        color: #929292;
        font-weight: 500;
        position: relative;
        background-image: linear-gradient(#bef0f0, #bef0f0);
        background-repeat: no-repeat;
        background-size: 100% 30%;
        background-position: 0 95%;
        transition: background-position 200ms cubic-bezier(0, 0.8, 0.13, 1), background-size 200ms cubic-bezier(0, 0.8, 0.13, 1);
        padding: 2px 0;
        &:hover {
            opacity: 1;
            background-size: 100% 80%;
            background-position: 0 80%;
        }
    }
    a:not(:last-child) {
        margin-right: .75rem;
    }
}

.section-title {
    color: $brand-color;
    @media screen and (min-width: 767px) {
        display: flex;
        justify-content: flex-end;
        text-align: right;
    }
}

.pub-title a {
    color: $text-color;
}

.pub {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1.2rem;
    margin-bottom: 1.2rem;
}